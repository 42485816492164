<template>
  <div>
    <div class="row detail">

      <div class="col-sm-2">
        <div v-if="regulateur.depart == 0">
          <img :src="image(regulateur.strcode2)" />
        </div>
      </div>

      <div class="col-sm-8">
        <br/><img :src="svg(regulateur.regcouleur)" /><strong><span v-if="regulateur.depart == 0">Régulateur : </span><span v-else>Départ parrallèle : </span>{{ regulateur.nom }}</strong><br/>
        {{ regulateur.typereg }}<br/>
        {{ calcBrillances() }}
      </div>

      <div class="col-sm-2">
      <a :href="doc(regulateur.strcode3)" target="_blank"
        ><img src="../assets/img/pdf.png" data-toggle="tooltip" data-placement="top" title="Voir la notice du régulateur" /></a><br />
          <a href="#" @click="retour()"
            ><img src="../assets/img/return.png" data-toggle="tooltip" data-placement="top" title="Retourner sur la liste des régulateurs"
          /></a>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th colspan="7"><h2>Mesures</h2></th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col" v-if="regulateur.depart == 0">Charge</th>
          <th scope="col">Calibre</th>
          <th scope="col" v-if="regulateur.depart == 0">Continuité &ohm;</th>
          <th scope="col">Isolement M&ohm;</th>
          <th scope="col">Température</th>
          <th scope="col">Météo</th>
        </tr>
      </thead>
      <tbody v-for="mesure in mesures" :key="mesure.id">
          <tr >
            <td with="100">{{ mesure.dt | sdt }}</td>
            <td v-if="regulateur.depart == 0">{{ mesure.charge }}/8</td>
            <td>{{ mesure.calibre }}</td>
            <td v-if="regulateur.depart == 0">{{ mesure.continuite }} &ohm; (Max={{calcMaxContinuite()}})</td>
            <td>{{ mesure.isolement }} M&ohm; (Min={{calcMinIsolement(mesure.calibre)}})</td>
            <td>{{ mesure.temperature }} °C</td>
            <td>{{ mesure.meteo }}</td>
          </tr>
          <tr>
            <td with="100"></td>
            <td colspan="6">{{ mesure.commentaire }}</td>
          </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="7">
            <h2>
              Actions
              <button @click="addAction()" data-toggle="tooltip" data-placement="top" title="Ajouter une action"><i class="fa fa-plus-square"></i></button>
            </h2>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th colspan="4" scope="col"><i class="fa fa-comment"></i> Action</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
          <th scope="col"><i class="fa fa-folder-open-o"></i> En Cours</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="action.id" v-for="action in actions">
          <td with="100">{{ action.dt | sdt }}</td>
          <td colspan="5">{{ action.Sujet }}</td>
          <td><a href="#" @click="editAction(action.id)"><i class="fa fa-edit"></i></a></td>
          <td>
            <span v-if="action.solde==0"><i class="fa fa-folder-open-o"></i></span><span v-if="action.solde!=0"><i class="fa fa-folder"></i></span>
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="7">
            <h2>
              Interventions
              <button @click="addInter()" data-toggle="tooltip" data-placement="top" title="Ajouter une intervention"><i class="fa fa-plus-square"></i></button>
            </h2>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th colspan="5" scope="col"><i class="fa fa-comment"></i> Intervention</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="intervention.id" v-for="intervention in interventions">
          <td with="100">{{ intervention.dt | sdt }}</td>
          <td colspan="5">{{ intervention.Sujet }}</td>
          <td><a href="#" @click="editInter(intervention.id)"><i class="fa fa-edit"></i></a></td>
        </tr>
      </tbody>
    </table>
    <br/><br/>
  </div>
</template>

<script>
import store from "../store";
import { getPrefix, selectApi } from "../lib/api.js";
import { getPath } from "../lib/svg.js";

export default {
  name: "Regulateur",
  store: store,
  data() {
    return {
      id: this.$route.params.id,
      regulateur: {},
      counta: 0,
      actions: [],
      counti: 0,
      interventions: [],
      countm: 0,
      mesures: [],
    };
  },
  computed: {},
  filters: {
    sdt: function (dt) {
      if (!dt) return "";
      return (
        dt.substring(8, 10) +
        "/" +
        dt.substring(5, 7) +
        "/" +
        dt.substring(0, 4)
      );
    },
  },
  methods: {
    retour() {
      this.$router.push({ path: "/regulateurs" });
    },
    addAction() {
      this.$router.push({ path: "/addaction/0/0/" + this.id });
    },
    addInter() {
      this.$router.push({ path: "/addinter/0/0/" + this.id });
    },
    editAction(id) {
      this.$router.push({ path: "/addaction/" + id + "/0/" + this.id });
    },
    editInter(id) {
      this.$router.push({ path: "/addinter/" + id + "/0/" + this.id });
    },
    calcMinIsolement(calibre){
      let im = 0
      if ((this.regulateur.L != 0) || (this.regulateur.NbFeu != 0)) im=Math.round(calibre/((this.regulateur.L*10)+this.regulateur.NbFeu*2),2)
      return im
    },
    calcMaxContinuite(){
      return Math.round(((0.018)*((this.regulateur.L*1000)/6)+(0.1212*this.regulateur.NbFeu))*1.2,2)
    },
    calcBrillances(){
      if (this.regulateur.NbBrillance < 1) return ""
      let bri = ""
      if (this.regulateur.NbBrillance >= 1) bri=this.regulateur.brillance1
      if (this.regulateur.NbBrillance >= 2) bri+=","+this.regulateur.brillance2
      if (this.regulateur.NbBrillance >= 3) bri+=","+this.regulateur.brillance3
      if (this.regulateur.NbBrillance >= 4) bri+=","+this.regulateur.brillance4
      if (this.regulateur.NbBrillance >= 5) bri+=","+this.regulateur.brillance5
      return "Brillance"+(this.regulateur.NbBrillance==1 ? "" : "s")+" : "+bri
    },
    getMesures() {
      let id = this.id;
      let sql =
        "SELECT m.Dt as dt,m.charge,m.calibre,m.continuite,m.isolement,m.temperature,mt.libelle as meteo,m.commentaire FROM mesureregulateur as m" +
        " LEFT JOIN cgbcommun.tableref AS mt ON mt.id=m.meteo" +
        " WHERE m.idregulateur=" +
        id +
        " ORDER BY m.Dt ASC";
      selectApi(this, sql).then(
        (response) => {
          this.countm = response.data.count;
          this.mesures = response.data.result;
        },
        (response) => {
        }
      );
    },
    getActions() {
      let id = this.id;
      let sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id as idfeu,f.nom as nomfeu,c.strcode,a.solde FROM actionpiste as a LEFT JOIN maintenance as m ON m.id=a.idmaintenance LEFT JOIN feu as f on f.id=a.idfeu LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur WHERE a.client=1 and a.idregulateur=" +
        id +
        " ORDER BY a.dt ASC";
      selectApi(this, sql).then(
        (response) => {
          this.counta = response.data.count;
          this.actions = response.data.result;
        },
        (response) => {
        }
      );
    },
    getInterventions() {
      let id = this.id;
      let sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id as idfeu,f.nom as nomfeu,c.strcode FROM interpiste as a LEFT JOIN maintenance as m ON m.id=a.idmaintenance LEFT JOIN feu as f on f.id=a.idfeu LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur WHERE a.client=1 and a.idregulateur=" +
        id +
        " ORDER BY a.dt ASC";
      selectApi(this, sql).then(
        (response) => {
          this.counti = response.data.count;
          this.interventions = response.data.result;
        },
        (response) => {
        }
      );
    },
    image(code) {
      if (code == undefined || code == "") code = "REG.png";
      let ret = getPrefix() + "/img/" + code;
      return ret;
    },
    doc(code) {
      let ret = getPrefix() + "/doc/regulateurs/" + code;
      return ret;
    },
    svg(code) {
      let path = getPath(code, 0, false);
      return path;
    },
    refresh() {
      let idPiste = store.state.piste;
      let sql =
        "SELECT r.id,r.nom,m.strcode2,m.strcode3,m.libelle as typereg,r.depart,r.couleur as regcouleur" + 
        ",r.L,r.NbFeu,r.NbBrillance,r.brillance1,r.brillance2,r.brillance3,r.brillance4,r.brillance5"+
        " FROM regulateur as r" +
        " LEFT JOIN ###.tableref AS m ON m.id=r.idtype" +
        " WHERE r.idpiste=" +
        idPiste +
        " AND r.id=" +
        this.id;
      selectApi(this, sql).then(
        (response) => {
          this.regulateur = [];
          if (response.data.count > 0) {
            this.regulateur = response.data.result[0];
            this.getActions();
            this.getInterventions();
            this.getMesures();
          }
        },
        (response) => {
        }
      );
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

.detail {
  padding: 10px;
}
</style>